import React from "react";
import { Block } from "glamor/jsxstyle";
import { graphql } from "gatsby";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import PodcastEmbed from "../components/PodcastEmbed";
import SEO from "../components/SEO";

/**
 * Podcast template
 *
 * Shows a list of all of my most recent podcast episodes with embedded players
 * from Anchor and what not pulling from the RSS feed.
 */
const Podcast = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  const podcasts = data.allFeedGatsbyBlog.edges;

  return (
    <Layout title={siteTitle}>
      <h1 style={{ fontSize: "2.8rem", marginBottom: "1.5rem" }}>Podcast</h1>
      <SEO
        title={"Podcast | Conor Dewey"}
        description={`Sometimes I like audio for explaining ideas and exploring new topics. Have a listen into what's going on in my head.`}
        pathname={"podcast"}
      />
      <title>{"Podcast | Conor Dewey"}</title>
      <section>
        <Block
          fontSize="1.4rem"
          lineHeight="2rem"
          color="#5F656D"
          fontWeight="500"
          paddingBottom="2.5rem"
        >
          Sometimes I prefer{" "}
          <a
            href="https://anchor.fm/conordewey"
            target="_blank"
            rel="noopener noreferrer"
          >
            speaking
          </a>{" "}
          over writing for exploring new ideas. Have a listen into what's going
          on in my head.
        </Block>
      </section>
      {podcasts.map(({ node }) => {
        return (
          <PodcastEmbed
            title={node.title}
            link={node.link}
            date={node.pubDate}
            duration={node.itunes.duration}
          />
        );
      })}
    </Layout>
  );
};

export default Podcast;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allFeedGatsbyBlog(sort: { order: DESC, fields: [pubDate] }) {
      edges {
        node {
          title
          contentSnippet
          link
          pubDate
          itunes {
            duration
          }
        }
      }
    }
  }
`;
