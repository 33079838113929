import React from "react";

/**
 * Mini podcast preview component
 *
 * Embed from Anchor podcast set up
 */
const PodcastEmbed = ({ title, link, date, duration }) => {
  const month = date.split(" ")[2];
  const day = date.split(" ")[1];
  const year = date.split(" ")[3];
  const formattedDate = month + " " + day + ", " + year;

  return (
    <article key={link}>
      <header>
        <h2
          style={{
            marginBottom: "-1rem",
            fontSize: "1.2rem",
            marginTop: ".4rem",
            lineHeight: "2.2rem"
          }}
        >
          <a href={link} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </h2>
        <p
          style={{
            display: "block",
            fontSize: "1rem",
            fontWeight: "500",
            color: "#4A5568",
            lineHeight: "1.6rem",
            marginTop: "20px",
            marginBottom: "26px"
          }}
        >
          {formattedDate} • {Math.round(parseInt(duration) / 60)} min{" "}
        </p>
      </header>
    </article>
  );
};

export default PodcastEmbed;
